<template>
  <v-container class="pa-5">
    <div v-if="$route.path == '/createormprow/'+this.$route.params.id" class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
      <span>
        <router-link :to="{name: 'ViewAllORMP', params:{id: this.$route.params.id}}">
          <v-icon>mdi mdi-arrow-left</v-icon>
        </router-link>
      </span>
      <v-icon>mdi-new-box</v-icon>
      Create New ORMP Row 
    </div>
    <div v-else class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
      <span>
        <router-link :to="{name: 'ViewAllPRMP', params:{id: this.$route.params.id}}">
          <v-icon>mdi mdi-arrow-left</v-icon>
        </router-link>
      </span>
      <v-icon>mdi-new-box</v-icon>
      Create New PRMP Row 
    </div>
    <v-stepper
      v-model="e6"
      vertical
      >
      <v-stepper-step
        :complete="e6 > 1"
        @click="e6 = 1"
        step="1"
        >
        <h4>Risk Name</h4>
      </v-stepper-step>
  
      <v-stepper-content step="1">
        <v-select
          :items="RiskClassification"
          item-text="RiskClassification"
          item-value='.key'
          label="Select Risk Classification"
          outlined
          v-model="selectedRiskClassification"
            return-object
          >
        </v-select>
        <v-autocomplete
          :items="cascadeRiskName"
          item-text="RiskName"
          item-value="id"
          label="Select Risk Name"
          return-object
          v-model="selectedRisk"
          no-data-text="Please select a Risk Classification first"
          outlined
          >
        </v-autocomplete>
        <v-icon v-if="this.selectedRisk">mdi-information-outline </v-icon><span v-if="this.selectedRisk"> Info</span>
        <v-row>
          <v-col>
            <v-list v-if="this.selectedRisk">
              <v-list-item-title><h3>Risk Universe</h3></v-list-item-title>
              <v-list-item>{{riskNameDetails('RiskName')}}</v-list-item>
            </v-list>   
          </v-col>
            
          <v-col>
            <v-list v-if="this.selectedRisk">
              <v-list-item-title><h3>Universal Causes</h3></v-list-item-title>
              <v-list-item
                v-for="(line) in riskNameDetails('UniversalCauses')" 
                :key="line"
                >• {{line}}
              </v-list-item>
            </v-list>
          </v-col>

          <v-col>
            <v-list v-if="this.selectedRisk">
              <v-list-item-title><h3>Universal Impacts</h3></v-list-item-title>
              <v-list-item
                v-for="(line) in riskNameDetails('UniversalImpacts')" 
                :key="line"
                >
                • {{line}}
              </v-list-item>
            </v-list>  
          </v-col>

          <v-col>
            <v-list v-if="this.selectedRisk">
              <v-list-item-title><h3>Universal Treatments</h3></v-list-item-title>
              <v-list-item
                v-for="(line) in riskNameDetails('UniversalTreatments')" 
                :key="line"
                >
                • {{line}}
              </v-list-item>
            </v-list>  
          </v-col>
        </v-row>
          
        <v-btn
          color="primary"
          @click="e6 += 1"
          > 
           Continue
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 2"
        @click="e6 = 2"
        step="2"
        >
        <h4>Risk Definition</h4>
      </v-stepper-step>
  
      <v-stepper-content step="2">
        <v-textarea
          name="input-7-1"
          filled
          auto-grow
          clearable
          v-model="riskDefinition"
          >
        </v-textarea>
         <v-btn
          color="primary"
          @click="e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
  
      <v-stepper-step
        :complete="e6 > 3"
        step="3"
        @click="e6 = 3"
        >
        <h4>Objective</h4>
      </v-stepper-step>
      <v-stepper-content step="3">
        <!-- <v-label>N/A</v-label>
        <v-btn
          @click="nullField('objectiveInputFields')"
          icon
          class="ml-2"
        ><v-icon v-if="objectiveInputFields == 'N/A'">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon></v-btn> -->
        <v-btn
          :disabled="checkNull('objectiveInputFields')"
          @click="addNewField('objectiveInputFields')"
          class="float-right mr-1 add-button-position"
          fab
          small
          color="primary"
          >
          <v-icon dark>
          mdi-plus
          </v-icon>
        </v-btn>

        <div>
          <v-text-field 
          :disabled="checkNull('objectiveInputFields')"
          v-for="(field, index) in this.objectiveInputFields" 
          :key="index" 
          :placeholder="index !== 0 ? 'Input another Objective' : 'Input an Objective'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'objectiveInputFields')"
          >
        </v-text-field>
        </div>
        
        <v-row class="ml-1 mt-3">
          <v-btn
            color="primary"
            @click="e6 += 1"
            >
            Continue
          </v-btn>
          <v-btn @click="back(1)">
            Back
          </v-btn>
        </v-row>
        
      </v-stepper-content>
  
      <v-stepper-step
        :complete="e6 > 4"
        step="4"
        @click="e6 = 4"
        >
        <h4>Causes - Definition</h4>
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-btn
          @click="addNewField('causesInputFields')"
          class="float-right mr-1 add-button-position"
          fab
          small
          color="primary"
          >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>

        <v-text-field 
          v-for="(field, index) in this.causesInputFields" 
          :key="index" 
          :placeholder="index !== 0 ? 'Input another Cause' : 'Input a Cause'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'causesInputFields')"
          >
        </v-text-field>
        <v-col>
          <v-list v-if="this.selectedRisk">
            <v-list-item-title><h3>Universal Causes</h3></v-list-item-title>
            <v-list-item
              v-for="(line) in riskNameDetails('UniversalCauses')" 
              :key="line"
              >• {{line}}
            </v-list-item>
          </v-list>
        </v-col>        
        <v-btn
          color="primary"
           @click="e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
  
      <v-stepper-step 
        :complete="e6 > 5"
        @click="e6 = 5"
        step="5">
        <h4>Impact - Definition</h4>
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-btn
          @click="addNewField('impactsInputFields')"
          class="float-right mr-1 add-button-position"
          fab
          small
          color="primary"
          >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
        <v-text-field 
          v-for="(field, index) in this.impactsInputFields" 
          :key="index" 
          :placeholder="index !== 0 ? 'Input another Impact' : 'Input an Impact'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'impactsInputFields')"
          >
        </v-text-field>
        <v-col>
          <v-list v-if="this.selectedRisk">
            <v-list-item-title><h3>Universal Impacts</h3></v-list-item-title>
            <v-list-item
              v-for="(line) in riskNameDetails('UniversalImpacts')" 
              :key="line"
              >
              • {{line}}
            </v-list-item>
          </v-list>  
        </v-col>
        <v-btn
          color="primary"
          @click="e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
      <v-stepper-step 
        :complete="e6 > 6"
        step="6"
        @click="e6 = 6"
        >
        <h4>Affected Stakeholders</h4>
      </v-stepper-step>
      <v-stepper-content step="6">
        <!-- <v-btn
          @click="addNewField('selectedStakeholders')"
          class="float-right ml-3 mt-2 add-button-position"
          fab
          small
          color="primary"
          >
           <v-icon dark>
            mdi-plus
          </v-icon>
          </v-btn>
          <div v-for="(field, index) in this.selectedStakeholders" :key="index">
            <v-btn
            @click="deleteObjectiveInput(index, 'selectedStakeholders')"
            v-if="index !== 0 ? 'mdi mdi-delete' : ''"
            class="float-right ml-3 mt-2 add-button-position"
            fab
            small
            color="primary"
            >
           <v-icon dark>
            mdi-delete
            </v-icon>
        </v-btn> -->
        <v-autocomplete
          clearable
          multiple
          chips
          :search-input.sync="search"
          @input="search = ''"
          :items="AffectedStakeholder"
          item-text="AffectedStakeholder"
          item-value="AffectedStakeholder"
          label="Select Affected Stakeholder"
          return-object
          outlined
          v-model="selectedStakeholders"
          >
          <template v-slot:selection="{ attrs, item, select, selected, index }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(index)"
              >
              <strong>{{ item.AffectedStakeholder }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-autocomplete>
          <!-- </div> -->
        <v-btn
          color="primary"
          @click="e6 += 1"
        >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
      <v-stepper-step 
        :complete="e6 > 7"
        step="7"
        @click="e6 = 7"
        >
        <h4>Inherent Risk Rating</h4>
      </v-stepper-step>
      <v-stepper-content step="7">
        <v-row>
          <v-col class="mt-5">
            <v-select
              :items="LikelihoodLevel"
              item-text="Level"
              item-value=".key"
              return-object
              label="Select Likelihood Level"
              outlined
              v-model="selectedLikelihood"
              >
            </v-select>
          </v-col>
          <v-col>
            <v-list>
              <v-list-item v-if="this.selectedLikelihood"><h3>Frequency</h3></v-list-item>
                <v-list-item>
                 {{selectedLikelihood.Frequency}}
                </v-list-item>
              </v-list>  
          </v-col>

          <v-col>
            <v-list>
                <v-list-item v-if="this.selectedLikelihood"><h3>Probability</h3></v-list-item>
                <v-list-item>
                  {{selectedLikelihood.Probability}}
                </v-list-item>
              </v-list>  
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="RiskImpactLevel"
              item-text="Level"
              item-value=".key"
              return-object
              label="Select Impact Criteria Level"
              outlined
              v-model="selectedImpactLevel"
              >
            </v-select>
          </v-col>

          <v-col>
            <v-select
              :items="ImpactArr"
              item-text="name"
              item-value="id"
              label="Select Impact Classification"
              return-object
              outlined
              v-model="selectedImpactClassification"
              >
            </v-select>
          </v-col>

          <v-col>
            <v-list>
              <v-list-item 
                v-for="(impact, index) in this.displayImpact"
                :key="index" 
                >{{impact}}
              </v-list-item>
            </v-list>                   
          </v-col>
          <v-col>
            <v-list v-if="this.selectedLikelihood && this.selectedImpactLevel && selectedImpactClassification">
              <v-list-item><h3>Vulnerability Rating</h3></v-list-item>
              <v-list-item>
                {{computeVulnerability}}
              </v-list-item>
            </v-list> 
          </v-col>
        </v-row>
         
        <v-btn
          color="primary"
          @click="e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>

      <v-stepper-step 
        :complete="e6 > 8"
        step="8"
        @click="e6 = 8"
        >
        <h4>Risk Strategy</h4>
      </v-stepper-step>
      <v-stepper-content step="8">
        <v-select
          :items="RiskStrategy"
          item-text="RiskStrategy"
          item-value=".key"
          label="Select Risk Strategy"
          return-object
          outlined
          v-model="selectedRiskStrategy"
          >
        </v-select>
        <v-btn
          color="primary"
           @click="e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="back(1)">
          Back
        </v-btn>
      </v-stepper-content>
      
      <!-- <v-stepper-step 
        :complete="e6 > 8"
        step="8"
        @click="e6 = 8"
        >
       <h4>Risk Actions - Existing</h4>
      </v-stepper-step>
      <v-stepper-content step="8">
      <v-btn
      @click="addNewRiskAction"
      class="float-right ml-3 add-button-position"
      fab
      small
      color="primary"
      >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
      <div 
        v-for="(field, index) in this.riskActions" 
        :key="index" 
        class="mb-4"
        >
         <v-combobox
        v-model="field.causes"
        :items="mapCauses"
        item-text="text"
        item-value="value"
        chips
        clearable
        label="Select Causes"
        multiple
        prepend-icon="mdi-filter-variant"
        solo
      >
      <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        close
        @click="select"
        @click:close="remove(item,index)"
      >
        <strong>{{ item.text }}</strong>&nbsp;
          </v-chip>
        </template>
        </v-combobox>
         <v-text-field 
          :placeholder="index !== 0 ? 'Input another Risk Action' : 'Input a Risk Action'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'riskActions')"
          >
        </v-text-field>
    </div>

      
          <v-btn
          color="primary"
           @click="e6 += 1"
          >
          Continue
          </v-btn>
        <v-btn @click="back(1)">
          Cancel
        </v-btn>
      </v-stepper-content> -->

    <v-stepper-step 
      :complete="e6 > 9"
      step="9"
      @click="e6 = 9"
      >
      <h4>Risk Treatment Owner</h4>
    </v-stepper-step>
    <v-stepper-content step="9">
         <!-- <v-btn
          @click="addNewFieldRTO()"
          class="float-right ml-3 mt-2 add-button-position"
          fab
          small
          color="primary"
          >
           <v-icon dark>
            mdi-plus
          </v-icon>
          </v-btn>
          <div v-for="(field, index) in selectedriskTreatmentOwners" :key="index">
            <v-btn
            @click="deleteObjectiveInput(index, 'selectedriskTreatmentOwners')"
            v-if="index !== 0 ? 'mdi mdi-delete' : ''"
            class="float-right ml-3 mt-2 add-button-position"
            fab
            small
            color="primary"
            >
           <v-icon dark>
            mdi-delete
            </v-icon>
            </v-btn> -->
        <v-radio-group
          row
          v-model="type"
          >
          <v-radio
            label="Person"
            value="Person"
            >
          </v-radio>
          <v-radio
            label="Dept"
            value="Dept"
            >
          </v-radio>
        </v-radio-group>

        <div v-show="type == 'Dept'">
          <v-row>
            <v-col cols="12" sm="2" md="4" v-for="(item) in returnRiskTreatmentOwner" :key="item['.key']">
              <v-checkbox
                v-model="selectedriskTreatmentOwners"
                :value="item"
                :label="item.RiskTreatmentOwner"
                >
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
        
        <div v-show="type == 'Person'">
          <v-row>
            <v-col cols="12" sm="2" md="4" v-for="(item) in returnDepartmentMembers" :key="item['.key']">
              <v-checkbox
                v-model="selectedriskTreatmentOwners"
                :value="item"
                :label="item.RiskTreatmentOwner"
                >
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
       
              
           
            <!-- <v-select
            
            multiple
            :items="returnRTOItems"
            item-text="RiskTreatmentOwner"
            return-object
            label="Select Risk Treatment Owner"
            outlined
            v-model="selectedriskTreatmentOwners"
            >
            </v-select> -->
            <!-- <v-select"
            multiple
            v-else
            :items="returnDepartmentMembers"
            item-text="RiskTreatmentOwner"
            return-object
            label="Select Risk Treatment Owner"
            outlined
            v-model="selectedriskTreatmentOwners"
            ></v-select> -->
          <!-- </div> -->
        <v-btn
          v-if="$route.path == '/createormprow/'+this.$route.params.id"
          color="primary"
          @click="saveRMP('ORMP')"
          >
          Save
        </v-btn>
        <v-btn
          v-else
          color="primary"
          @click="saveRMP('PRMP')"
          >
          Save
        </v-btn>
          <v-btn @click="back(1)">
            Back
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        e6: 1,
        type: 'Person',
        ormpId: '',
        prmpId: '',
        riskDefinition: '',
        selectedRisk: '',
        selectedRiskClassification: '',
        selectedImpactClassification: '',
        selectedLikelihood: '',
        selectedImpactLevel: '',
        selectedRiskStrategy: '',
        search: '',
        ImpactArr: [
          {id: 'Environment', name: 'Environment'},
          {id: 'HSS', name: 'Health, Safety & Security'},
          {id: 'LegalCompliance', name: 'Legal and Compliance'},
          {id: 'NIAT', name: 'Net Income After Tax'},
          {id: 'MGTACTION', name: 'Mgt Action'},
          {id: 'Operational', name: 'Operational'},
          {id: 'Reputation', name: 'Reputation'},
        ],
        objectiveInputFields: [
          {
            text: ''
          }
        ],
        causesInputFields: [
          {
            text: ''
          }
        ],
        impactsInputFields: [
          {
            text: ''
          }
        ],
        selectedStakeholders: [],
              // riskActions: [
              //   {
              //     text: '',
              //     causes: []
              //   }
              // ],
        selectedriskTreatmentOwners: []
      }
    },

    props: ['id'],

    firestore(){
      return {
        Risk: this.$db.collection('Risk'),
        AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
        RiskStrategy: this.$db.collection('RiskStrategy'),
        RiskTreatmentOwner: this.$db.collection('RiskTreatmentOwner'),
        LikelihoodLevel: this.$db.collection('LikelihoodLevel').orderBy("Level", "desc"),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel'),
        RiskClassification: this.$db.collection('RiskClassification'), 
        UserAccounts: this.$db.collection('UserAccounts'),
      }
    },

    methods: {
      checkNull(data){
        if(this[data][0] == 'N/A'){
          return true
        } else {
          return false
        }
      },

      nullField(data){
        if(data == 'objectiveInputFields'){
          if(this[data] == "N/A"){
            this[data] = [
              {
                text: ''
              }
            ]
          } else {
            this[data] = ["N/A"]
          }
        }
      },

      mapMulti(arr, fieldName){
        let mapped = []
        this[arr].forEach(e1=>{
          mapped.push({
            ['.key']: e1.text['.key'], 
            [fieldName]:e1.text[fieldName]
          })
        })
        return mapped   
      },

      mapMultiRTO(){
        let mapped = []
        this.selectedriskTreatmentOwners.forEach(e1=>{
          mapped.push({
            ['.key']: e1.text['.key'], 
            RiskTreatmentOwner: e1.text.RiskTreatmentOwner,
            type: e1.type
          })
        })
        return mapped   
      },

      remove(index) {
        this.selectedStakeholders.splice(index, 1)
      },
      
      back() {
        this.e6 -= 1;
      },

      computeVulnerabilityMethod(){
        return this.selectedLikelihood.Level * this.selectedImpactLevel.Level
      },

      addNewRiskAction() {
        this.riskActions.push({text: ''})
      },

      displayRiskRating(criteria){
        let arr = this.LikelihoodLevel
        if(this.selectedLikelihood === ''){
          return ''
        } else {
          let filter = arr.filter(e => {
            return e['.key'] == this.selectedLikelihood['.key']
          })      
          return filter[0][criteria]
        }
      },

      deleteObjectiveInput(index, arrayName) {
        this[arrayName].splice(index, 1)
      },

      riskNameDetails(risk) {
        let arr = this.Risk
        if(this.selectedRisk.id === ''){
          return ''
        } else {
          let filter = arr.filter(e => {
            return e['.key'] == this.selectedRisk.id
          })
            return filter[0][risk]
        }
      },

      addNewField(arrayName) {
        this[arrayName].push({text: ''})
      },

      addNewFieldRTO() {
        this.selectedriskTreatmentOwners.push({text: '', type: 'Person'})
      },

      saveFields(arrayName){
        let i
        let errIndex = []
        if(this[arrayName].length === 1){
          for (i=0; i < this[arrayName].length; i++){
            if(this[arrayName][i].text === ""){
              alert("pls enter a value for field" + " " + (i+=1))
            } else {
              this.e6 += 1;
            }
          }
        } else if(this[arrayName].length > 1) {
          for (i=0; i < this[arrayName].length; i++){
            if(this[arrayName][i].text === ""){
              errIndex.push(i)    
            } 
          } if(errIndex.length > 0) {
            for(i=0; i < errIndex.length; i++){
              alert("please enter a value for field " + (errIndex[i]+=1))
            } 
          } else {
            this.e6 += 1;
          }     
        }
      },

      mapInput(arr){
        if(this[arr] == 'N/A'){
          return this[arr]
        } else {
          let values = this[arr].map(function(value) {
            return value['text']
          });
          return values;
        }
      },

      mapLikelihood() {
        let arr = this.LikelihoodLevel
        if(this.selectedLikelihood === ''){
          return ''
        } else {
          let filter = arr.filter(e => {
            return e['.key'] == this.selectedLikelihood
          })      
          return {id:this.selectedLikelihood, Level:filter[0].Level}
        }
      },

      mapImpact() {
        let arr = this.RiskImpactLevel
        if(this.selectedImpactLevel === ''){
          return ''
        } else {
          let filter = arr.filter(e => {
            return e['.key'] == this.selectedImpactLevel['.key']  
          }) 
          if (this.selectedImpactClassification === '') {
            return []
          } else {
            return {
              ['.key']:this.selectedImpactLevel['.key'], 
              Level:filter[0].Level, 
            }
          }
        }
      },
          
      async saveRMP(plan){
        let self = this
        let data = {
          CoverageId: this.$route.params.id,
          CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
          CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
          Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
          Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
          RiskClassification: this.selectedRiskClassification,
          RiskName: this.selectedRisk,
          RiskDefinition: this.riskDefinition,
          Objectives: this.mapInput('objectiveInputFields'),
          Causes: this.mapInput('causesInputFields'),
          Impacts: this.mapInput('impactsInputFields'),
          AffectedStakeholders: this.selectedStakeholders,
          LikelihoodLevel: this.selectedLikelihood,
          ImpactLevel: this.mapImpact(),
          ImpactClassification: this.selectedImpactClassification,
          RiskStrategy: this.selectedRiskStrategy,
          // RiskActions: this.riskActions,
          // RiskTreatmentOwner: this.mapMulti('selectedriskTreatmentOwners', 'RiskTreatmentOwner'),
          RiskTreatmentOwner: this.selectedriskTreatmentOwners,
          Vulnerability: this.computeVulnerabilityMethod()
        }
        const confirm = await this.$dialog.confirm({
            text: "Do you really want to save this new "+ plan+"?",
            title: "Confirm New "+ plan
        })
        if(confirm){
          if(plan == 'ORMP'){
            self.$db.collection(plan).add(data)
            .then((doc)=>{
              let id = doc.id
              this.ormpId = doc.id
              let saveLevel = {
                ORMPId: id,
                CoverageId: this.$route.params.id,
                RiskStrategy: this.selectedRiskStrategy,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
                Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
                RiskManagementLevel: 'Inherent',
                // RiskActions: this.riskActions,
                LikelihoodLevel: this.selectedLikelihood,
                ImpactLevel: this.mapImpact(),
                ImpactClassification: this.selectedImpactClassification,
                RiskTreatmentOwner: this.selectedriskTreatmentOwners,
                Vulnerability: this.computeVulnerabilityMethod()
              }
              self.$db.collection('ORMPLevels').add(saveLevel).then(async(doc)=>{
                let id = doc.id
                await self.$store.dispatch('logs/createNotifs', {collection:'ORMP', documentKey:this.ormpId, module: 'ORMP', action: 'Added New ORMP Row'}) 
                await self.$store.dispatch('logs/createNotifs', {collection:'ORMPLevels',documentKey:id, module: 'ORMP', action: 'Added New ORMP Level'}) 
                self.$dialog.notify.success(`Added New Risk`, {
                  position: 'bottom-right',
                  timeout: 3000
                })      
                self.$router.push('/viewallormp/'+this.$route.params.id)      
              })       
            })
          } else {
            self.$db.collection(plan).add(data)
            .then((doc)=>{
              let id = doc.id
              this.prmpId = doc.id
              let saveLevel = {
                PRMPId: id,
                CoverageId: this.$route.params.id,
                RiskStrategy: this.selectedRiskStrategy,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM' ? this.$store.getters['logs/getDataOwner'] : this.$store.getters['useraccount/isAuthenticated'].department,
                Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
                RiskManagementLevel: 'Inherent',
                // RiskActions: this.riskActions,
                LikelihoodLevel: this.selectedLikelihood,
                ImpactLevel: this.mapImpact(),
                ImpactClassification: this.selectedImpactClassification,
                RiskTreatmentOwner: this.selectedriskTreatmentOwners,
                Vulnerability: this.computeVulnerabilityMethod()
              }
              self.$db.collection('PRMPLevels').add(saveLevel).then(async(doc)=>{
                let id = doc.id
                await self.$store.dispatch('logs/createNotifs', {collection: plan, documentKey:this.prmpId, module: plan, action: 'Added New PRMP Row'}) 
                await self.$store.dispatch('logs/createNotifs', {collection:'PRMPLevels',documentKey:id, module: plan, action: 'Added New PRMP Level'}) 
                self.$dialog.notify.success(`Added New Risk`, {
                  position: 'bottom-right',
                  timeout: 3000
                })      
                self.$router.push('/viewallprmp/'+this.$route.params.id)      
              })       
            })
          }              
        }
      },
      
      formatName(user){
        let email = user.emailAdd
        let splitLast = email.split('@')
        let name = splitLast[0]
        let splitName = name.split('.')
        let firstname = splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1)
        let lastname = splitName[1].charAt(0).toUpperCase() + splitName[1].slice(1)
        let fullname = firstname + ' ' + lastname
        return {['.key']: user['.key'], RiskTreatmentOwner: fullname, Type: 'Person'}
      }
    },

    computed: {
      returnRiskTreatmentOwner(){
        if(this.RiskTreatmentOwner.length == 0){
          return []
        } 
        let arr = this.RiskTreatmentOwner
        let newArr = []
        arr.forEach(e=>{
          let x = {...e}
          x.Type = 'Department'
          newArr.push(x)
        })
        return newArr
      },  
        
      returnDepartmentMembers(){
        let arr = []
        if(this.UserAccounts.length == 0){
          return []
        }
        let filtered = this.UserAccounts.filter(e=>{
          return e.department == this.$store.getters['useraccount/isAuthenticated'].department
        })
        filtered.forEach(e=>{
          arr.push(this.formatName(e))
        })
        //  console.log(arr)
        //  console.log(this.RiskTreatmentOwner)
        return arr
      },

      returnAffectedStakeholder(){
        let arr1 = this.selectedStakeholders
        let arr2 = this.AffectedStakeholder
        let intersectionArr = arr1.filter(element => arr2.includes(element))
        return intersectionArr 
      },    
      
      cascadeRiskName() {
        let arr = this.Risk
        if(this.selectedRiskClassification == ''){
          return []
        } else {
          let filter = arr.filter(e => {
            return e.RiskClassification['.key'] == this.selectedRiskClassification['.key']
          })
          return filter.map(e => {
            return {
              id: e['.key'],
              RiskName: e.RiskName  
            }
          })
        }    
      },

      displayImpact() {
        let id = this.selectedImpactClassification.id
        let arr = this.RiskImpactLevel
        if(this.selectedImpactLevel === ''){
          return []
        } else {
          let filter = arr.filter(e => {
            return e['.key'] == this.selectedImpactLevel['.key']
          })      
          return filter[0][id]    
        }
      },

      mapCauses() {
        if(this.causesInputFields.length === 0){
          return ''
        } else {
          let map = this.causesInputFields.map(function(currentValue, index){
            return {text:currentValue.text, value: index}
          })
          return map
        }
      },

      computeVulnerability() {
        return this.selectedLikelihood.Level * this.selectedImpactLevel.Level
      },
    }
  }
</script>

<style scoped>
h4:hover{
  cursor: pointer;
  color: gray;
}
h4{
  font-weight: normal;
}
a:link {
  text-decoration: none;
}
</style>
